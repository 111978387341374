input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Hide the default arrow */
select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}


.all-container {
    position: relative;
}

/* Notification */
.notify {
    position: absolute;
    width: 300px;
    height: 50px;
    background: #fff;
    right: 15px;
    top: 15px;
    display: flex;
    flex-direction: row;  
    padding: 0px 5px;
    align-items: center;  
    box-shadow: 0px 7px 20px 0px rgba(197, 215, 227, 0.19);
}

.progress {
    --bg-color: 255, 255, 255;

    display: block;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: rgba(var(--bg-color), 0.486);
    position: relative;
    overflow: hidden;
}
.progress b {
    --percent: 50%;
    position: absolute;
    left: 0;
    border-radius: 2px;
    width: var(--percent, 50%);
    height: 100%;
    background-color: rgb(var(--bg-color, #FFF));
}

.mainDiv {
    height: 2rem;
    width: 500px;
    background-color: whitesmoke;
    border-radius: 12px;
    margin: 1rem;
 }
 .childDiv {
    height: 100%;
    width: 60%;
    background-color: green;
    border-radius: 12px;
    text-align: left;
 }
 .text {
    color: white;
    padding-left: 30px;
    font-size: 1.4rem;
    font-weight: 700;
 }



.main-contentyu {
    height: 100vh;
}

.form-label {
    color: var(--primary-main-black, #000);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.form-control {
    font-size: 13px;
}

.major-width {
    width: 460px;
    height: fit-content;
    align-items: center;
    border-radius: 12px;
    margin-top: 81px;
    padding: 16px 24px 100px 24px;
    /* border: 2px solid var(--grey-outline-color, #E7E9EB); */
    background: var(--new-color-white, #FFF);
    
}

.major-width.continue {
    padding: 16px 24px 30px 24px;
}

.migi h4, .migi h3{
    font-weight: 500;
    text-transform: capitalize;
    font-size: 24px;
}

.migi p{
    font-weight: 400;
    font-size: 13px;
    margin: 15px auto;
}

.input-set {
    padding: 12px 0px;
}

.mine-input {
    padding: 10px 12px;
}

.form-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    background: #FE6E04;
    border-radius: 4px;
    border: none;
    font-size: 13px;
    font-weight: 500;
    margin: 20px 0px 15px 0px;
    cursor: pointer;
}

.form-submit:hover {
    background: #ff8935;
}

.register-prompt {
    text-align: center;
    width: 100%;
    color: var(--new-color-secondary, #717171);
    font-size: 10px;
}

a {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    cursor: pointer;    
}

.register-head {
    width: 100%;
}

.form-submit.reg {
    color: var(--new-color-white, #FFF);
}

.registration-tab {
    display: none;
    transition: all 2s ease-in-out;
}

.registration-tab.active {
    display: block;
}

.phone-input-container {
    display: flex;
    flex-direction: row;
    padding: 0px;
}

.phone-select {
    width: 35px;
    margin: 5px;
    /* margin-right: 0px;
    padding-right: 5px; */
    border: none;
    background: #FE6E04;
}

.mine-input.num-sel {
    border: none;
    height: 100%;
    flex-grow: 1;
    border-left: 2px solid gainsboro;
}

.gender {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.gender-pick {
    width: auto;
    padding: 10px;
    margin: 0px 5px;
    font-size: 11px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid gainsboro;
    cursor: pointer;
}

.gender-pick.active {
    border: 2px solid black;
}

/* Password Input */
.passwordIput-container{
    position: relative;
}
.passwordIput-container input {
    width: 100%;
}

.passwordIput-container .nigga {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 40px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
}

.passwordIput-container svg {
    color: #717171;
    height: 24px;
    width: 24px;
}

.policy {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 500;
    color: black;
}

.policy input {
    border-radius: 3px;
    margin-top: 3px;
    margin-right: 15px;
}

.policy a {
    color: #1363DF;
    cursor: pointer;
}

.control-btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}
.control-btns > button {
    width: 47%;
    padding: 10px;
}
.btn.white {
    background: transparent;
    border: 1.5px solid #000;
}

/* Verification Container */
.verification-container {
    display: flex;
    justify-content: center;
}

.verification-container input {
    width: 55px;
    height: 43px;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    margin: 0 5px;
    border: 2px solid #000;
    border-radius: 7px;
    outline: none;
}

.verification-container input:focus {
    border-color: #007bff;
}

.verification-container input::placeholder{
    text-align: center;
}

.verify-send {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin: 20px 0px 60px 0px;
}
.major-width.borders {
    padding-bottom: 45px;
}

.close-button {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}

.btn.full {
    width: 100%;
    padding: 10px;
}

.con-rel {
    position: relative;
}

.loader-container {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    background: #2b2b1f80;
    z-index: 3;
}
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid #6210CC;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.dash-button-plain {
    padding: 5px 10px;
    border: 1px solid var(--new-color-nav-color, #B0B0B0);
    background: var(--new-color-white, #FFF);
    font-size: 10px;
    height: fit-content;
}

.dash-power-btns {
    width: fit-content;
    display: flex;
    margin: auto;
    margin-top: 45px;
}

.dash-power-btns button {
    margin: 3px 8px;
    color: #000;
    font-weight: 400;
}

.temp-contain {
    border: 1px solid black;
    border-radius: 7px;
    background: #fff;
}

.dash-body {
  flex-direction: row;
}

@media (min-width: 200px) and (max-width: 500px) {
    .dash-body {
        display: flex;
        flex-direction: column;
    }

}

 

 
.waiting-signal p {
    animation: pulse 2s infinite alternate; /* Use the 'pulse' animation for infinite loop */
  }
  
  @keyframes pulse {
    from {
      opacity: 1; /* Start with full opacity */
    }
    to {
      opacity: 0.1; /* End with half opacity */
    }
  }
  
  /* DateFilter.css */
.date-filter {
  width: 100%;
  max-width: 320px;
  background-color: #1a202c;
  color: white;
  border-radius: 8px;
  overflow: hidden;
}

.country-selector {
  display: flex;
  border-bottom: 1px solid #2d3748;
}

.country-button {
  flex: 1;
  padding: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.country-button:hover {
  background-color: #2d3748;
}

.country-button.selected {
  background-color: #3182ce;
}

.filter-content {
  padding: 16px;
}

.filter-title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.calendar-container {
  background-color: #2d3748;
  border-radius: 8px;
  padding: 16px;
}

.month-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.month-selector button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.month-year {
  font-size: 14px;
  font-weight: bold;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  margin-bottom: 16px;
}

.day-name {
  font-size: 12px;
  text-align: center;
  color: #a0aec0;
}

.calendar-day {
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calendar-day:hover:not(:disabled) {
  background-color: #4a5568;
}

.calendar-day.selected {
  background-color: #3182ce;
}

.calendar-day:disabled {
  color: #4a5568;
  cursor: default;
}

.time-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-unit button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.time-value {
  font-size: 24px;
  font-weight: bold;
  margin: 4px 0;
}

.time-separator {
  font-size: 24px;
  font-weight: bold;
  margin: 0 16px;
}

.filter-button {
  width: 100%;
  padding: 12px;
  background-color: #48bb78;
  color: white;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-button:hover {
  background-color: #38a169;
}

/* PlatformSelector.css */
.platform-selector {
  background-color: #1a202c;
  color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.selector-title {
  font-size: 18px;
  margin-bottom: 12px;
}

.platform-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.platform-button {
  display: flex;
  align-items: center;
  background-color: #2d3748;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.platform-button:hover {
  background-color: #4a5568;
}

.platform-button.selected {
  background-color: #3182ce;
}

.platform-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.platform-name {
  font-size: 14px;
}
/* TeamSelector.css */
.team-selector {
  background-color: #1a202c;
  color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.selector-title {
  font-size: 18px;
  margin-bottom: 12px;
}

.team-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
}

.team-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2d3748;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.team-button:hover {
  background-color: #4a5568;
}

.team-button.selected {
  background-color: #3182ce;
}

.team-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 4px;
}

.team-name {
  font-size: 12px;
  text-align: center;
}

/* LeagueSelector.css */
.league-selector {
  background-color: #1a202c;
  color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.selector-title {
  font-size: 18px;
  margin-bottom: 12px;
}

.league-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 8px;
}

.league-button {
  background-color: #2d3748;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.league-button:hover {
  background-color: #4a5568;
}

.league-button.selected {
  background-color: #3182ce;
}

/* SummaryStatistics.css */
.summary-statistics {
  background-color: #1a202c;
  color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.summary-title {
  font-size: 18px;
  margin-bottom: 12px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 12px;
}

.stat-item {
  background-color: #2d3748;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stat-label {
  font-size: 12px;
  margin-bottom: 4px;
  color: #a0aec0;
}

.stat-value {
  font-size: 18px;
  font-weight: bold;
}

/* GoalsChart.css */
.goals-chart {
  background-color: #1a202c;
  color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.chart-title {
  font-size: 18px;
  margin-bottom: 12px;
}

/* SummaryStatistics.css */
.summary-statistics {
  background-color: #2d3748;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 20px;
  font-weight: bold;
  color: #63b3ed;
  margin-bottom: 15px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.stat-item {
  background-color: #4a5568;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stat-value {
  font-size: 18px;
  font-weight: bold;
  color: #63b3ed;
  margin-bottom: 5px;
}

.stat-label {
  font-size: 12px;
  color: #a0aec0;
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .stat-value {
    font-size: 16px;
  }
  
  .stat-label {
    font-size: 11px;
  }
}

/* SummaryStatistics.css */
.summary-statistics {
  background-color: #2d3748;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 24px;
  font-weight: bold;
  color: #63b3ed;
  margin-bottom: 20px;
}

.stat-category {
  margin-bottom: 25px;
}

.category-title {
  font-size: 18px;
  font-weight: bold;
  color: #4fd1c5;
  margin-bottom: 15px;
  border-bottom: 1px solid #4a5568;
  padding-bottom: 5px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.stat-item {
  background-color: #4a5568;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stat-label {
  font-size: 14px;
  color: #a0aec0;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 18px;
  font-weight: bold;
  color: #63b3ed;
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  
  .stat-value {
    font-size: 16px;
  }
  
  .stat-label {
    font-size: 12px;
  }
}