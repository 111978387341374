/* Verification */
.major-width.verify {
  padding-bottom: 20px;
  position: relative;
  .btn {
    width: 100%;
    padding: 10px;
  }
  input {
    width: 47px;
    height: 47px;
    border-radius: 5px;
  }
  input:not(:placeholder-shown) {
    background: #eeedec;
    color: #351ae8;
  }
  .verification-container {
    margin-top: 50px;
  }
  .verify-send {
    margin-bottom: 40px;
  }
}

.notify {
  .icon {
    height: fit-content;
    padding: 3px;
    border-radius: 50%;
    background: var(--grey-background-white, #F4F4F7);
    margin-left: 5px;
  }
  .text {
    height: inherit;
    padding: 10px;
    flex-grow: 1;
    p {
      margin: 0px;
      padding: 0px;
      font-size: 9px;
      color: var(--primary-main-black, #000);
      font-weight: 300;
    }
    h6 {
      margin: 0px;
      padding: 0px;
      font-weight: 500;
      font-size: 11px;
    }
  }
}



.otps {
  input {
    width: 47px;
    height: 47px;
    border-width: 1px;
    border-radius: 3px;
  }
  input:not(:placeholder-shown) {
    background: #FE6E04;
    color: #FFF;
  }
}

#custom-select {
  position: relative;
  display: flex;
  flex-direction: row;
  select {
    flex-grow: 1;
    border: none;
    option {
      width: calc(100% + 30px);
    }
  }
  .customdrop-icon {
    position: absolute;
    height: 100%;
    right: 0;
    width: auto;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

#custom-select.open{
  .customdrop-icon {
    transform: rotate(180deg);
  }
}


.dashboard-width {
  width: 100%;
  padding: 0px 40px;
}

.dash-body {
  display: flex;

  .dash-balance {
    width: 400px;
    height: fit-content;
    background:#FFF;
    margin: 0px 10px;
    padding: 30px 10px 20px 10px;
    border-radius: 10px;
    h2 {
      span {
        font-size: 20px;
      }
    }
  }
  .dash-outlet {
    flex-grow: 1;
    margin: 0px 10px;
    max-width: 450px;

    h2 {
      font-weight: 400;
    }
  }

  .plans {
    max-width: 450px;
    h2 {
      margin-bottom: 15px;
    }
  }

  .planes {
    margin-bottom: 25px;
  }

  .plan-head{
    display: flex;
    justify-content: space-between;
  }
}

.individual-plans {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  padding: 16px;
  border-radius: 10px;
  background: var(--new-color-white, #FFF);
  border: 1px solid #000;  
  height: fit-content;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin: 5px 0px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: black;
    text-transform: capitalize;
    margin: 0px;
  }
  .title {
    color: #717171;
  } 
  .start {
    text-transform: uppercase;
  }
}

.dash-header {
  width: 100%;
  min-height: 50px;
  margin-bottom: 30px;
  padding: 20px;
  h1 {
    font-size: 30px;
    color:#FE6E04;
    text-transform: capitalize;
  }
}

.temp-contain {
  cursor: pointer;
  height: fit-content;
  padding: 10px 14px;
  margin-bottom: 15px;
  h4 {
    padding: 0px;
    margin: 0px;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 500;
    color: #000;
  }
  p {
    color: #717171;
    font-weight: 400;
    font-size: 12px;
    margin: 0px;
  }
  svg {
    float: right;
  }
  .spooIcon {
    margin-right: 20px;
    padding: 7px;
    background: #F2F2F2;
    border-radius: 50%;
    img {
      height: 30px;
      width: 30px;
    }
  }
}

.earnings-detail {
  background: #04D88C;
  padding: 60px 20px 20px 20px;
  border-radius: 10px;
  font-weight: 900;
  margin-bottom: 20px;
  h1 {
    font-size: 35px;
  }
  h5 {
    font-size: 16px;
  }
  button {
    text-transform: capitalize;
    border: none;
    border-radius: 11px;
    margin: 0px 6px;
    padding: 3px 10px;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
}

.earnings-exp {
  border: 1px solid black;
  border-radius: 11px;
  background: #FFF;
  padding: 20px;
  div {
    display: flex;
    justify-content: space-between;
    height: fit-content;
    margin-bottom: 12px;
    p{
      margin: 0px;
      font-size: 15px;
      font-weight: 500;
      color: #000;
    }

    .light {
      color: #717171;
    }
  }
}

.earnings-balance {
  border: 1px solid black;
  border-radius: 7px;
  background: #FFF;
  margin: 40px 0px 20px 0px;
  align-items: center;
  padding: 1px 10px;
  h1, p {
    margin: 0px;
    font-size: 40px;
    color: #04D88C;
  }
  p {
    font-size: 16px;
    color: #000;
  }
}



@media (min-width: 200px) and (max-width: 500px) {
  .dash-body {
    width: 100%;
    .dash-balance {
      margin-bottom: 30px;
      width: 100%;
      h2 {
        font-size: 39px;
      span {
        font-size: 27px;
      }
    }
    }
    .dash-outlet {
      width: 100%;
    }
  }
  .dashboard-width {
     padding: 0px 10px 0px 0px;
  }
  .dash-button-plain.spif{
    font-size: 12px;
    padding: 8px 19px;
  }
}




@media (min-width: 200px) and (max-width: 500px) {
    .savings {
      display: none;
    }

    .mobileBack {
      display: none;
    }
}



$primary-dark-bg: #0f1d3e;


* {
  margin: 0;
  padding: 0;
}

body {
  // height: 100vh;
}

.plain-dash {
  height: 100vh;
}

.sidebars {
  .film {
    svg {
      display: none;
    }
  }
  h1 {
    color: #e0e0e0;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
  }
  .drop-btn {
    color: #fcfcfc;
    border: none;
    height: fit-content;
    width: fit-content;
    background-color: transparent;
  }
}

.mid-action {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  color: #e0e0e0;
  ul {
    padding-left: 10px;
    color: #e0e0e0;
    padding: 0;
    gap: 10px;
    li {
      list-style: none;
      padding: 10px;
      width: 100%;
      a {
        font-weight: 400;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #e0e0e0;
        svg {
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          text-transform: capitalize;
        }
      }
    }
    li.active, li:hover {
      background-color: #8c87875e;
      border-right: 3px solid #e0e0e0;
    }
  }
}

.user-disp {
  margin: 10px 0;
  padding: 0 10px;
}

.user-image {
  width: 35px;
  height: 34px;
  background-color: blue;
  border-radius: 50%;
}

.user-det {
  height: inherit;
  text-transform: capitalize;
  .name {
    font-size: 12px;
    line-height: 13.68px;
  }
  p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 15.5x;
    text-wrap: nowrap;
  }
}

.logout-btn {
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 12px;
  text-align: left;
  padding: 10px;
  border: none;
  box-shadow: none;
  color: #e40000;
}

// Top Section, Main Body
.top-section {
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  .names {
    justify-content: center;
    font-weight: 400;
    p {
      font-size: 15px;
      line-height: 17px;
      padding: 0;
      margin: 0;
    }
    h1 {
      font-size: 20px;
      line-height: 22px;
      padding: 0;
      margin: 0;
    }
  }
  .mist {
    svg {
      display: none;
    }
  }
  .main-atn {
    padding: 10px;
    background-color: $primary-dark-bg;
    color: #e0e0e0;
    border-radius: 5px;
    font-size: 13px;
    text-transform: capitalize;
    line-height: 23px;
    cursor: pointer;
    // &:hover {
  }
}

.wallet-view {
  // background-color: #5719D0;
  height: fit-content;
  max-height: fit-content;
  padding: 20px;
  border-radius: 8px;
}
.wallet-atn {
  button {
    border: none;
    background-color: transparent;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    padding: 8px 20px;
    color: #7d7d7d;
    border-radius: 5px;
    box-shadow: none;
    &.active, &:focus, &:hover {
      color: #fff;
      background-color: $primary-dark-bg;
    }
  }
  svg {
    color: #7d7d7d;
    &.active, &:hover {
      color: black;
    }
  }
}

.ligue {
  height: 30px;
}

.indiv-wallet {
  box-sizing: border-box;
  position: relative;
  //: "ion:eye-off-outline"}
  border-radius: 10px;
  padding: 15px;
  min-width: 330px;
  max-width: 330px;
  overflow: hidden;
  background-color: #5719D0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #fff;
  .fifth {
    text-transform: capitalize;
    span {
      text-transform: uppercase;
    }
  }

  .bottom {
    margin-top: 25px;
    h2, p {
      padding: 0;
      margin: 0;
    }
    h2 {
      font-size: 29px;
      line-height: 32px;
    }
    p {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 22px;
    }
  }
}

.all-wallets {
  > div:nth-child(odd)::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 3px;  /* Adjust width as needed */
    height: 50%;
    background-color: #6210CC; /* Change color as desired */
  }
  > div:nth-child(even) {
    background-color: #fcfcfc;
  }
}


.no-scrollbar {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.flints {
  padding: 0 20px;
  > div {
    margin: 7px 0;
  }
}

.quick-actions {
  text-transform: capitalize;
  font-weight: 300;
  h1 {
    font-size: 17px;
    line-height: 25px;
    font-weight: 400;
  }
  button {
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    color: #7d7d7d;
    text-transform: capitalize;
    border-radius: 5px;
    height: fit-content;
    width: fit-content;
    text-wrap: nowrap;
    padding: 5px 25px;
    border: none;
    cursor: pointer;
    &:hover{
      background-color: #f3efef;
    }
  }
}


.transaction {
  box-sizing: border-box;
  .all-transaction {
    gap: 12px;
    box-sizing: border-box;
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
  }
  .indiv-transaction {
    background-color: #f3efef;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 5px;
    .transaction-details {
      p {
        color: rgb(63, 211, 63);
      }
    }
  }
  .images {
    height: 35px;
    width: 35px;
    background-color: #0f1d3e;
    border-radius: 50%;
  }
  h5, p {
    padding: 0;
    margin: 0;
    font-weight: 300;
    text-transform: capitalize;
    color: #7d7d7d;
  }
  h5 {
    font-weight: 400;
    color: $primary-dark-bg;
  }
}

.ad-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  background-repeat: no-repeat;
}


@media (min-width: 200px) and (max-width: 650px) {
  .action-bars {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    .film {
      svg {
        display: block;
      }
    }
  }
  .action-bars.active {
    display: block;
  }
  .actuators {
    position: relative;
  }

  .top-section {
    .mist {
      svg {
        display: block;
      }
    }
    .brian {
      svg {
        display: none;
      }
    }
  }
}

.all-wallets {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

/* Add additional styling for IndividualWallets component */
.IndividualWallets {
  flex: 0 0 auto;
  width: 300px; /* Adjust as needed */
  scroll-snap-align: start;
}

.wallet-slider-container {
  position: relative;
}

.slide-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.slide-indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.slide-indicator.active {
  background-color: #555; /* Change to active color */
}


  @media (min-width: 800px){
    .mobile{
      display: none !important;
    }
  }

